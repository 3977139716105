/** @jsxRuntime classic */
/** @jsx jsx */

// NOTE: Based on SsrUniveralLayout from zapier/zapier

import { jsx } from "@emotion/react";
import { emit } from "@zapier/events-toolkit-browser";
import { UniversalLayout } from "@zapier/universal-layout";
import { useRouter } from "next/router";

import type { JSX } from "react";

type UniversalLayoutProps = React.ComponentProps<typeof UniversalLayout>;

export type SsrUniversalLayoutProps = Partial<UniversalLayoutProps>;

export function SsrUniversalLayout(
  props: SsrUniversalLayoutProps,
): JSX.Element {
  const router = useRouter();
  return (
    <UniversalLayout
      currentUrl={router.pathname}
      mode="navigation"
      trackEvent={(eventName, options = {}) => {
        emit({ system_name: "partner", ...options }, eventName);
      }}
      {...props}
    >
      {props.children}
    </UniversalLayout>
  );
}
