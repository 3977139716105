/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  Button,
  Colors,
  Dropdown,
  Field,
  Link,
  Spacer,
  Spinner,
  Text,
} from "@zapier/design-system";
import { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import { useIsClient } from "usehooks-ts";
import { useQueryParamBoolean } from "src/utils/useQueryParamBoolean";
import { useWorkflowElement } from "./generator/hooks/useWorkflowElement";
import LinkButton from "src/components/LinkButton";
import { fetchJSON } from "src/utils/fetchJSON";

type ZapierSession = {
  is_staff: boolean;
};

const styleIntegrationsContainer = (isFullWidth = false) =>
  css({
    backgroundColor: `${Colors.GrayWarm2}`,
    padding: isFullWidth ? 0 : 20,
    borderRadius: 8,
  });

const styleFlexCenter = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const styleParagraphShortCenter = (size: string) =>
  css({
    maxWidth: size,
    margin: "0 auto",
  });

const styleFullWidthAppPicker = css`
  display: grid;
  grid-template-columns: auto max-content;
  gap: 10px;
`;

const styleFullWidthAppPickerButton = css`
  display: flex;
  flex-direction: column;
  /* Make the button line up with the select */
  align-self: start;
  padding-top: 25px;
`;

export interface ZapierIntegration {
  id: number;
  title: string;
  public: boolean;
  slug: string;
  status: "beta" | "public" | "private";
}

interface DropdownOption {
  key: number;
  slug: string;
  label: string;
}

function zapierIntegrationToOption(
  integration: ZapierIntegration,
): DropdownOption {
  return {
    key: integration.id,
    slug: integration.slug,
    label: integration.title,
  };
}

interface OAuthApp {
  allowlist_domains: string[];
  client_id: string;
  id: number;
  redirect_uris: string[];
}

interface AppSelectorProps {
  continueURL: URL;
  "data-testid"?: string;
  isWorkflowElement?: boolean;
  isFullWidth?: boolean;
}

export const AppSelector: React.FC<AppSelectorProps> = ({
  continueURL,
  "data-testid": dataTestId,
  isWorkflowElement = false,
  isFullWidth = false,
}) => {
  const workflowElement = useWorkflowElement();
  const { clientId, integrationId, serviceSlug } =
    workflowElement.elementConfig ?? workflowElement.defaultConfig;
  const dontShowAppSelector =
    isWorkflowElement && clientId && integrationId && serviceSlug;

  const zapierOrigin = process.env.NEXT_PUBLIC_ZAPIER_ORIGIN;

  const [zapierIntegration, setZapierIntegration] = useState<DropdownOption>();
  const [isAppSelectionError, setIsAppSelectionError] = useState("");

  const oauthAppURL = new URL("/api/v4/oauth/applications/", zapierOrigin);
  const slug = zapierIntegration?.slug;
  if (slug) {
    oauthAppURL.searchParams.set("service_slug", slug);
  }
  const oauthAppResult = useSWR(slug ? oauthAppURL.href : null, async (url) => {
    const resp = await fetch(url, { credentials: "include" });
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    const json = await resp.json();
    const app: OAuthApp = json?.results?.[0];
    return app;
  });

  const isClient = useIsClient();

  const integrationsURL = new URL("/api/platform/cli/apps", zapierOrigin);

  const loginURL = new URL("/app/login", zapierOrigin);
  if (isClient) {
    loginURL.searchParams.set(
      "next",
      // The origin is not allowed in the `next` URL for login, so just pass all
      // the pieces starting with the `pathname`. Include `#integrations` to
      // make the browser scroll back to the `integrations` HTML ID since that's
      // where the login link is rendered.
      location.pathname +
        location.search +
        `${isWorkflowElement ? "" : "#integrations"}`,
    );
  }

  const getStartedURL = new URL(continueURL);
  getStartedURL.searchParams.set(
    "client_id",
    oauthAppResult?.data?.client_id ?? "",
  );
  getStartedURL.searchParams.set(
    "integration_id",
    String(zapierIntegration?.key || ""),
  );
  getStartedURL.searchParams.set("service_slug", zapierIntegration?.slug || "");

  const devPlatformURL = new URL("https://developer.zapier.com");
  devPlatformURL.searchParams.set("utm_source", "zapier_marketing_website");
  devPlatformURL.searchParams.set("utm_medium", "embed_experience");
  devPlatformURL.searchParams.set("utm_campaign", "goto_my_integrations");

  // Secret query param to test the UI for the "no integrations" scenario
  const testNoIntegrations = useQueryParamBoolean(
    "__test.no_integrations",
    false,
  );

  const isZapierStaffResponse = useSWR("/api/v4/session/", async (key) => {
    const data = await fetchJSON<ZapierSession>({ url: key });
    return data.is_staff;
  });

  const integrations = useSWR(integrationsURL.href, async (key) => {
    const resp = await fetch(key);
    if (!resp.ok) {
      throw new Error("Please log in to continue");
    }
    const data = await resp.json();
    const integrations: ZapierIntegration[] = data.objects;
    return integrations.filter((x) => x.public || x.status === "beta");
  });

  // [Workflow Element] Set the client ID and integration ID when the user
  // clicks "Get Started"
  const handleGetStartedClick = () => {
    workflowElement.setGeneratorProperty(
      "clientId",
      oauthAppResult?.data?.client_id ?? "",
    );
    workflowElement.setGeneratorProperty(
      "integrationId",
      String(zapierIntegration?.key ?? ""),
    );
    workflowElement.setGeneratorProperty(
      "serviceSlug",
      zapierIntegration?.slug ?? "",
    );
  };

  const isZapierStaff =
    !isZapierStaffResponse.isLoading &&
    !isZapierStaffResponse.isValidating &&
    isZapierStaffResponse.data;

  useEffect(() => {
    if (!isZapierStaff) {
      return;
    }
    // eslint-disable-next-line no-console
    console.info(
      "Hold `Alt` and right click the 'Choose your app' dropdown to input a custom service slug",
    );
  }, [isZapierStaff]);

  useEffect(() => {
    // It's possible for the Oauth to not exists when an app is upgraded. This is here until that is fixed
    // See https://zapierorg.atlassian.net/browse/TPS-1784
    if (zapierIntegration && oauthAppResult.data === undefined) {
      setIsAppSelectionError(
        "No OAuth integration exists for this app. Please contact Zapier customer service for support.",
      );
    } else {
      setIsAppSelectionError("");
    }
  }, [zapierIntegration, oauthAppResult]);

  // [Workflow Element] Don't show the app selector if the user is
  // already logged in and has an app
  if (dontShowAppSelector) {
    return null;
  }

  return (
    <div
      data-testid={dataTestId}
      // Add a secret menu for Zapier staff to choose an app by entering a
      // Zapier service slug. the `contextmenu` event is generally activate by
      // using right click or control+click on a Mac.
      onContextMenu={
        isZapierStaff
          ? (event) => {
              // Require Alt/Option or Meta (Command/Windows) key in addition to
              // right clicking to make it harder to do on accident.
              if (!(event.altKey || event.metaKey)) {
                return;
              }
              event.preventDefault();
              const slug = window.prompt("Enter a Zapier service slug");
              if (!slug) {
                return;
              }
              setZapierIntegration({
                key: -1,
                label: slug,
                slug: slug,
              });
            }
          : undefined
      }
    >
      <div>
        <div css={styleIntegrationsContainer(isFullWidth)}>
          {integrations.data?.length === 0 || testNoIntegrations ? (
            <div css={styleFlexCenter}>
              <Text
                tag="p"
                color="GrayWarm9"
                type="Body4"
                textAlign="center"
                margin="0 0 15px 0"
              >
                You have no public integrations
              </Text>
              <div css={styleParagraphShortCenter("40ch")}>
                <Text
                  tag="p"
                  color="GrayWarm9"
                  type="Body1"
                  textAlign="center"
                  margin="0 0 15px 0"
                >
                  You can join an existing integration as a collaborator or
                  build a new one.
                </Text>
              </div>
              <LinkButton href={devPlatformURL.href} size="small">
                Go to My Integrations
              </LinkButton>
            </div>
          ) : integrations.error ? (
            <div css={styleFlexCenter}>
              <Text tag="p" type="Body2" margin="0 0 10px 0" textAlign="center">
                Log in to Zapier to view your app.
              </Text>
              <LinkButton size="small" href={loginURL.href}>
                Log in
              </LinkButton>
            </div>
          ) : integrations.data === undefined ? (
            <div css={styleFlexCenter}>
              <Spinner size="medium" />
            </div>
          ) : (
            <div css={isFullWidth ? styleFullWidthAppPicker : ""}>
              <Field
                label="Choose your app"
                isRequired
                helpTextLineClamp={4}
                renderHelpText={() =>
                  !!isAppSelectionError || (
                    <Fragment>
                      Zap Templates are required to embed Zapier.{" "}
                      <Link
                        href="https://platform.zapier.com/partners/zap-templates"
                        target="_blank"
                      >
                        Zap Templates
                      </Link>{" "}
                      are simple workflows with the triggers and actions
                      pre-selected.
                    </Fragment>
                  )
                }
                renderInput={(fieldProps) => (
                  <Dropdown
                    {...fieldProps}
                    isErrored={!!isAppSelectionError}
                    size="large"
                    key={integrations.data?.length}
                    placeholder="Select"
                    onSelect={setZapierIntegration}
                    items={(integrations.data || []).map(
                      zapierIntegrationToOption,
                    )}
                  />
                )}
                error={isAppSelectionError}
              />
              {!isFullWidth && <Spacer height={10} />}
              <Fragment>
                {isWorkflowElement ? (
                  <div css={isFullWidth ? styleFullWidthAppPickerButton : ""}>
                    <Button
                      size="large"
                      disabled={!zapierIntegration || !!isAppSelectionError}
                      data-testid="appSelector:getStarted"
                      onClick={handleGetStartedClick}
                    >
                      Get started
                    </Button>
                    <Spacer height={5} />
                  </div>
                ) : (
                  <LinkButton
                    disabled={!zapierIntegration || !!isAppSelectionError}
                    size="large"
                    href={getStartedURL.href}
                    data-testid="appSelector:getStarted"
                  >
                    Get started
                  </LinkButton>
                )}
              </Fragment>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
