/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";
import { Text } from "@zapier/design-system";
import { escape as escapeHTML } from "html-escaper";
import { FC, Fragment } from "react";
import CodeBlock from "src/components/CodeBlock";
import {
  AppParamsPlaceholder,
  CodeExampleProps,
  styleExampleLayout,
} from "./CodeExample";
import { CodeBlockWithPlaceholders } from "../CodeBlockWithPlaceholders";
import { QuacCodeAlert } from "./QuacCodeAlert";
import { CodePlaceholder } from "./CodePlaceholder";

export const CodeExampleReact: FC<CodeExampleProps> = ({ params, element }) => {
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="SectionHeader">
        A solution for React
      </Text>
      <Text tag="p">
        Custom elements (web components) can be used with React. Use the{" "}
        <strong>main.js</strong> snippet as an example. Add the{" "}
        <strong>jsx-types.d.ts</strong> snippet (note the <strong>.d</strong> in{" "}
        <strong>.d.ts</strong>) if you use TypeScript.
      </Text>
      <CodeBlock
        title="HTML (<head>)"
        code={`\
<!-- See the "Vanilla JS" solution if you'd rather use JS to load these -->
<script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
`}
        showCopyButton={true}
      />
      <CodeBlockWithPlaceholders
        data-testid="codeBlock:body"
        title="main.js"
        alert={<QuacCodeAlert />}
      >
        {`\
export default function App() {
  return (
    <div>
      <h1>Zapier Elements React</h1>
      <${element}
`}
        {[...params.entries()]
          .filter(([key]) => key !== "id")
          .map(([key, val]) => {
            const indent = " ".repeat(8);
            if (val instanceof AppParamsPlaceholder) {
              return (
                <Fragment key={key}>
                  {indent}
                  {key}=<CodePlaceholder value={val} />
                  {"\n"}
                </Fragment>
              );
            }
            const safeVal =
              typeof val === "string"
                ? `"${escapeHTML(val)}"`
                : `{${JSON.stringify(val)}}`;
            return `${indent}${key}=${safeVal}\n`;
          })}
        {`\
      />
    </div>
  );
}
  `}
      </CodeBlockWithPlaceholders>
      <CodeBlock
        title="jsx-types.d.ts (React 18)"
        code={`\
declare namespace JSX {
  interface IntrinsicElements {
    "${element}": any;
  }
}
`}
        showCopyButton={true}
      />
      <CodeBlock
        title="jsx-types.d.ts (React 19)"
        code={`\
import "react";

declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      "${element}": any;
    }
  }
}
`}
        showCopyButton={true}
      />
    </div>
  );
};
