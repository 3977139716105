/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */

import { css, jsx } from "@emotion/react";
import {
  Colors,
  ContentSection,
  Heading,
  Link,
  Shadows,
  Spacer,
  Text,
  Typography,
} from "@zapier/design-system";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { Fragment, type JSX } from "react";
import { ErrorBoundary } from "react-error-boundary";
import BrowserUI from "src/components/BrowserUI";
import { ErrorFallback } from "src/components/ErrorFallback";
import { AppSearchToggle } from "src/components/generator/AppSearchToggle";
import CodeExample, {
  AppParams,
  AppParamsPlaceholder,
} from "src/components/generator/CodeExample";
import { GuessZapToggle } from "src/components/generator/GuessZapToggle";
import { useWorkflowElement } from "src/components/generator/hooks/useWorkflowElement";
import { IntroToZapierToggle } from "src/components/generator/IntroToZapierToggle";
import Layout from "src/components/generator/Layout";
import { SidebarHeader } from "src/components/generator/SidebarHeader";
import ThemeSettings from "src/components/generator/ThemeSettings";
import { WorkflowAppSelector } from "src/components/generator/WorkflowAppSelector";
import { WorkflowsToggle } from "src/components/generator/WorkflowsToggle";
import { YourUserZapsToggle } from "src/components/generator/YourUserZapsToggle";
import PlugAndPlayHeader from "src/components/PlugAndPlayHeader";
import { SsrUniversalLayout } from "src/components/SsrUniversalLayout";
import { clickOnSpace } from "src/utils/clickOnSpace";
import { ZAPIER_API_ORIGIN, ZAPIER_ORIGIN } from "src/utils/env";
import { Filterable, serializeFilterable } from "src/utils/filterable";
import mediaQuery from "src/utils/mediaQuery";
import { useIsClient } from "usehooks-ts";

const stylePreviewHeader = css({
  background: Colors.PrimeWhite,
  margin: "0 -20px",
  paddingTop: 40,
  paddingBottom: 0,
  borderBottom: `1px solid ${Colors.GrayWarm3}`,
});

const styleCardGrid = css`
  display: grid;
  gap: 15px;
`;

const styleSettingsListToggles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${Colors.GrayWarm4};
  box-shadow: ${Shadows.elevation5};
`;

const styleFlex = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const styleSidebar = css`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 15px 10px 10px;
  border-bottom: 1px solid ${Colors.GrayWarm3};
  box-sizing: border-box;

  ${mediaQuery.large} {
    overflow-y: scroll;
    padding: 15px 15px 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const styleMain = css`
  padding-bottom: 20px;
`;

const styleMainFZE = css`
  padding: 0 20px;
`;

const styleBorder = css`
  display: block;
  margin-bottom: 30px;
  border-top: 1px solid ${Colors.GrayWarm3};
`;

const styleHidden = css({ "&[hidden]": { display: "none" } });

const styleTextCenter = css`
  text-align: center;
`;

const styleFontBase = css({ ...Typography.base });

export interface Category extends Filterable {
  title: string;
}

interface PageProps {
  categoriesList: Category[];
}

export const getServerSideProps: GetServerSideProps<PageProps> = async () => {
  // getServerSideProps is needed for Vercel+Split integration. We can not use getServerSideProps + getStaticProps so
  // api.zapier.com/elements has been relocated here for now.

  const props: PageProps = { categoriesList: [] };

  try {
    const resp = await fetch(
      `${ZAPIER_API_ORIGIN}/elements/categories/?limit=100&role=all`,
    );
    let categories: Category[] = [];
    if (resp.ok) {
      const json = await resp.json();
      categories = json.results || [];
    }
    props.categoriesList = categories;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`${ZAPIER_API_ORIGIN}/elements error`, err);
  }

  return { props };
};

export default function WorkflowElementGenerator({
  categoriesList,
}: PageProps): JSX.Element {
  const workflowElement = useWorkflowElement();

  // Workflow element variables
  const theme =
    workflowElement.elementConfig?.theme ?? workflowElement.defaultConfig.theme;
  const zapTemplates =
    workflowElement.elementConfig?.templates ??
    workflowElement.defaultConfig.templates;
  const appSearchBarDisplay =
    workflowElement.elementConfig?.appSearchBarDisplay ??
    workflowElement.defaultConfig.appSearchBarDisplay;
  const appExclusions =
    workflowElement.elementConfig?.appExclusions ??
    workflowElement.defaultConfig.appExclusions;
  const appCategories =
    workflowElement.elementConfig?.appCategories ??
    workflowElement.defaultConfig.appCategories;
  const showZapTemplates =
    workflowElement.elementConfig?.showTemplates ??
    workflowElement.defaultConfig.showTemplates;
  const workflowType =
    workflowElement.elementConfig?.workflowType ??
    workflowElement.defaultConfig.workflowType;
  const mode =
    workflowElement.elementConfig?.mode ?? workflowElement.defaultConfig.mode;

  // required for the generator
  const clientId =
    workflowElement.elementConfig?.clientId ??
    workflowElement.defaultConfig.clientId;
  const integrationId =
    workflowElement.elementConfig?.integrationId ??
    workflowElement.defaultConfig.integrationId;
  const serviceSlug =
    workflowElement.elementConfig?.serviceSlug ??
    workflowElement.defaultConfig.serviceSlug;

  const showGenerator = clientId && integrationId && serviceSlug;

  const clientLoaded = useIsClient();

  const templateIds = zapTemplates.map((zt) => zt.id).join(",");

  // Relying on defaults doesn't always work when we toggle back and forth
  // between states. Not sure what's going on here 100%, but you probably
  // shouldn't add/remove props normally from our Stencil components (WFE).
  const defaultParams = {
    "intro-copy-display": "show",
    "manage-zaps-display": "show",
    "app-limit": 0,
    "app-search-bar-display": "show",
    "template-cta-display": "show",
    "zap-create-from-scratch-display": "hide",
    "template-style": "card",
    "template-limit": 10,
    "data-internal-demo-mode": "few-zaps",
  };

  const appParams: AppParams = (() => {
    // Due to HTML attributes and URL query parameters only supporting strings,
    // we've limited the props of `<zapier-workflow>` to only strings and
    // numbers.

    // Most of these state variables can also be undefined, to support just
    // letting the generated code use default values. That's why there's a bunch
    // of `typeof` checks below. We avoid adding the props if it's undefined, to
    // let the default values come through.
    const map: AppParams = new Map();

    map.set(
      "sign-up-email",
      new AppParamsPlaceholder("email_of_your_user@example.com"),
    );
    map.set(
      "sign-up-first-name",
      new AppParamsPlaceholder("first_name_of_your_user"),
    );
    map.set(
      "sign-up-last-name",
      new AppParamsPlaceholder("last_name_of_your_user"),
    );

    // Required props
    if (workflowElement.elementConfig?.clientId) {
      map.set("client-id", workflowElement.elementConfig.clientId);
    }

    // Global props
    map.set("theme", theme);

    // Intro section
    if (typeof workflowElement.elementConfig?.introCopyDisplay === "boolean") {
      map.set(
        "intro-copy-display",
        workflowElement.elementConfig.introCopyDisplay ? "show" : "hide",
      );
    }

    // Zap manager section
    if (typeof workflowElement.elementConfig?.manageZapsDisplay === "boolean") {
      map.set(
        "manage-zaps-display",
        workflowElement.elementConfig.manageZapsDisplay ? "show" : "hide",
      );
    }

    // Guess Zap section
    if (typeof workflowElement.elementConfig?.guessZapDisplay === "boolean") {
      map.set(
        "guess-zap-display",
        workflowElement.elementConfig.guessZapDisplay ? "show" : "hide",
      );
    }

    // App search section
    if (appSearchBarDisplay === undefined) {
      // Do nothing
    } else if (appSearchBarDisplay) {
      map.set("app-search-bar-display", appSearchBarDisplay ? "show" : "hide");
      if (typeof workflowElement.elementConfig?.appLimit === "number") {
        map.set("app-limit", workflowElement.elementConfig.appLimit);
      }
      if (appExclusions.length > 0) {
        map.set(
          "app-exclusions",
          appExclusions.map((app) => app.slug).join(","),
        );
      }
      if (appCategories.length > 0) {
        map.set(
          "app-categories",
          appCategories.map(serializeFilterable).join(","),
        );
      }
    } else {
      map.set("app-selection-display", "hide");
    }

    // Popular workflows section
    if (showZapTemplates) {
      if (workflowType === "popular") {
        if (typeof workflowElement.elementConfig?.templatesLimit === "number") {
          map.set(
            "template-limit",
            workflowElement.elementConfig.templatesLimit,
          );
        }
      } else if (workflowElement.elementConfig?.workflowType === "specific") {
        map.set("template-ids", templateIds);
      }

      if (workflowElement.elementConfig?.templateStyle) {
        map.set("template-style", workflowElement.elementConfig.templateStyle);
      }

      if (
        typeof workflowElement.elementConfig?.templateCallToActionDisplay ===
        "boolean"
      ) {
        map.set(
          "template-cta-display",
          workflowElement.elementConfig.templateCallToActionDisplay
            ? "show"
            : "hide",
        );
      }

      if (
        typeof workflowElement.elementConfig?.zapCreateFromScratchDisplay ===
        "boolean"
      ) {
        map.set(
          "zap-create-from-scratch-display",
          workflowElement.elementConfig?.zapCreateFromScratchDisplay
            ? "show"
            : "hide",
        );
      }
    } else {
      map.set("template-limit", "0");
      map.set("zap-create-from-scratch-display", "hide");
    }

    return map;
  })();

  const workflowURL = new URL("/partner/embed/workflow", ZAPIER_ORIGIN);

  // Displays SsrUniversalLayout while the generator is hidden
  const AppSelectorWrapper = !showGenerator ? SsrUniversalLayout : "div";

  return (
    <div css={styleFontBase}>
      <Head>
        <title>Workflow Element | Zapier</title>
      </Head>
      <AppSelectorWrapper>
        <WorkflowAppSelector workflowURL={workflowURL} />
      </AppSelectorWrapper>
      {showGenerator && (
        <Layout
          header={<PlugAndPlayHeader isWorkflowElement={true} />}
          aside={
            <div css={styleSidebar}>
              <SidebarHeader />
              <div
                hidden={mode !== "editing"}
                css={[styleHidden, styleCardGrid]}
              >
                <IntroToZapierToggle
                  containerClassName={styleSettingsListToggles}
                  contentWrapperClassName={styleFlex}
                />
                <YourUserZapsToggle
                  containerClassName={styleSettingsListToggles}
                  contentWrapperClassName={styleFlex}
                />
                <GuessZapToggle
                  containerClassName={styleSettingsListToggles}
                  contentWrapperClassName={styleFlex}
                />
                <AppSearchToggle
                  categoriesList={categoriesList}
                  clientLoaded={clientLoaded}
                  containerClassName={styleSettingsListToggles}
                  contentWrapperClassName={styleFlex}
                />
                <WorkflowsToggle
                  clientLoaded={clientLoaded}
                  containerClassName={styleSettingsListToggles}
                  contentWrapperClassName={styleFlex}
                />
                <Spacer height={10} />
              </div>
            </div>
          }
          main={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <div css={[styleMain]}>
                {mode === "done" && (
                  <Fragment>
                    <Text
                      margin="40px 0 30px"
                      tag={Heading}
                      type="PageHeader"
                      textAlign="center"
                    >
                      Embed the Workflow Element
                    </Text>
                    <span css={styleBorder}></span>
                    <ContentSection size="medium">
                      <CodeExample
                        params={appParams}
                        element="zapier-workflow"
                        integrationID={integrationId}
                        serviceSlug={serviceSlug}
                        shouldShowQuacBanner={true}
                      />
                    </ContentSection>
                  </Fragment>
                )}

                <div
                  // Keep this content in the DOM so the WFE doesn't have to
                  // reload its data with network calls when it's remounted. This
                  // makes the experience feel much faster when editing.
                  css={styleHidden}
                  hidden={mode !== "editing"}
                >
                  <div css={styleMainFZE}>
                    {workflowElement.elementConfig?.clientId && (
                      <>
                        <div css={stylePreviewHeader}>
                          <Text
                            tag={Heading}
                            type="PageHeader"
                            textAlign="center"
                          >
                            Embed the Workflow Element
                          </Text>
                          <ThemeSettings
                            theme={workflowElement.elementConfig.theme}
                            color={workflowElement.elementConfig.bgColor}
                            setTheme={(theme) =>
                              workflowElement.setGeneratorProperty(
                                "theme",
                                theme,
                              )
                            }
                            setColor={(color) =>
                              workflowElement.setGeneratorProperty(
                                "bgColor",
                                color,
                              )
                            }
                          />
                        </div>
                        <div css={styleTextCenter}>
                          <Spacer height={20} />
                          <Text textAlign="center" tag="p">
                            This is how it will look inside your product. Click
                            on{" "}
                            <Link
                              href="#"
                              role="button"
                              onKeyDown={clickOnSpace}
                              onClick={(event) => {
                                event.preventDefault();
                                workflowElement.setGeneratorProperty(
                                  "mode",
                                  "done",
                                );
                              }}
                            >
                              Generate code
                            </Link>{" "}
                            to get the embed code.
                          </Text>
                          <Text textAlign="center" tag="p">
                            Note: If you are trying to embed the Workflow
                            Element in an iframe, links will open in a popup.
                          </Text>
                        </div>
                        <Spacer height={20} />
                        <BrowserUI
                          bgColor={workflowElement.elementConfig.bgColor}
                        >
                          <zapier-workflow
                            {...defaultParams}
                            {...Object.fromEntries(appParams)}
                          />
                        </BrowserUI>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          }
        />
      )}
    </div>
  );
}
